<template>
  <header>
<!--    <svg-->
<!--      width="8vw"-->
<!--      height="8vw"-->
<!--      viewBox="0 0 92 92"-->
<!--      fill="none"-->
<!--      xmlns="http://www.w3.org/2000/svg"-->
<!--    >-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M62.6612 45.4081L58.1177 51.1959L66.8208 90.3987L90.9377 59.404L62.6612 45.4081ZM91.9991 59.1611L66.4657 91.9763L57.3848 51.0715L57.3778 51.037L57.375 51.027L62.4651 44.5427L62.4679 44.5441L91.9991 59.1611Z"-->
<!--        fill="#4FD8FF"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M55.1249 40.9196V40.921L55.1207 40.9182L46.0117 36.4228L46.0131 36.4214L53.2837 32.8329L53.3188 32.8157L55.1249 40.9196ZM54.1401 39.6664L52.8374 33.8211L47.5668 36.4224L54.1401 39.6664Z"-->
<!--        fill="#01582D"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M62.4688 44.5441L91.9999 59.1611L71.57 32.844L71.5693 32.8431V32.8445L71.5483 32.8172L71.5469 32.8158L71.5441 32.8187L62.4688 44.5441ZM71.5454 33.9419L63.5282 44.3003L89.6157 57.2127L72.2578 34.8529V34.8667L71.5454 33.9419Z"-->
<!--        fill="#6DBD46"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M25.5117 91.9757H66.467L57.3864 51.0724L57.3861 51.0709L57.379 51.0364L57.3762 51.0278L25.5117 91.9757ZM57.0213 52.6051L26.92 91.2871H65.6088L57.0213 52.6051Z"-->
<!--        fill="#517BBC"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M66.2926 27.1828L54.1081 33.1887L55.7263 40.4496L62.27 43.677L70.6732 32.8198L66.2926 27.1828ZM71.5446 32.819L62.4693 44.5444L62.4356 44.5272L62.4342 44.5258L55.144 40.9301L55.1257 40.92L53.3197 32.8161L53.2846 32.8333L53.2832 32.8276L66.4909 26.3174L66.5007 26.3304L66.5119 26.3447H66.5133L71.5446 32.819Z"-->
<!--        fill="#048041"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M82.8656 18.2115L66.5 26.3298L66.5112 26.3456H66.5126L71.5439 32.8184L71.5467 32.8155L71.5481 32.8184L82.8656 18.2115ZM71.5451 31.698L80.4834 20.1618L67.5608 26.5721L71.5451 31.698Z"-->
<!--        fill="#AB9B3E"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M53.3174 32.8111L66.49 26.3167L46.0342 0.028895L46.0117 0.000152588L53.3174 32.8111ZM47.3496 2.84054L53.799 31.8059L65.4277 26.0728L47.3496 2.84054Z"-->
<!--        fill="#6DBD46"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M46.0116 36.4222L45.9836 36.4093L38.7298 32.8294L38.7031 32.815L36.8984 40.9204L36.9069 40.9161L46.0116 36.4222ZM37.8831 39.6664L44.4549 36.4227L39.1844 33.8216L37.8831 39.6664Z"-->
<!--        fill="#517BBC"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M36.9077 40.9158V40.9172L25.5117 91.9753L57.3762 51.0274L55.1369 40.9719L55.1312 40.969L55.1341 40.9618L55.1214 40.9187L46.0111 36.4234L46.0139 36.4219L46.0122 36.421L45.9872 36.4076L45.9844 36.409L46.0125 36.4219L36.9077 40.9158ZM46.0104 37.1908L37.5083 41.3872L26.8518 89.132L56.6336 50.8605L54.5351 41.4371L54.2637 41.2981L54.2751 41.2689L46.0104 37.1908Z"-->
<!--        fill="#7ACCFF"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M25.5028 91.9396L25.5112 91.9756L25.5201 91.9358L36.9072 40.9175L36.8988 40.9204L16.4373 51.019L16.4359 51.0205V51.019L16.4219 51.0277L16.4303 51.0622L25.5028 91.9396ZM17.2116 51.4047L25.5129 88.8072L35.9221 42.1703L17.2116 51.4047Z"-->
<!--        fill="#FAB962"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M36.8974 40.9212L38.7021 32.8158L25.5337 26.3172L25.5239 26.3287L14.1699 40.9212L14.1797 40.9643L16.4205 51.027L16.4345 51.0198H16.4359L36.8974 40.9212ZM16.9021 50.0219L36.297 40.4497L37.9125 33.194L25.7318 27.1828L14.9127 41.0879L16.9021 50.0219Z"-->
<!--        fill="#FDE46A"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M39.4928 32.4376L46.0112 3.16715L52.5234 32.4143L52.4777 32.4349L52.4839 32.46L46.0202 35.6509L45.9866 35.6337L45.9779 35.6381L39.4928 32.4376ZM46.0144 36.4216L45.9864 36.4073L45.9836 36.4087L38.7298 32.8288L38.7031 32.8159L45.9934 0.079713L46.0018 0.043785L46.0116 0.000671387L46.0222 0.0484414L53.3173 32.8116L53.2822 32.8274L53.2836 32.8331L46.0144 36.4216Z"-->
<!--        fill="#2FD986"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M46.0117 0L46.0342 0.0287424L66.49 26.3166H66.4914L66.4999 26.3295L82.8654 18.2126L46.0117 0ZM81.3135 18.2138L48.4005 1.94857L66.698 25.4626L81.3135 18.2138Z"-->
<!--        fill="#E3FF71"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M91.9986 59.162L82.8644 18.2127L71.5469 32.8181L71.5679 32.8454V32.844L91.9986 59.162ZM72.4187 32.8171L90.6572 56.3112L82.5113 19.7925L72.4187 32.8171Z"-->
<!--        fill="#BCD557"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M25.5332 26.3166L38.7016 32.8152L45.9906 0.0863031L45.9919 0.0804788L46.0003 0.0445508L46.0084 0.0077061L46.0101 0L46.0059 0.00574849L45.961 0.0632333L25.5332 26.3166ZM44.6715 2.84174L26.5953 26.0729L38.2201 31.8098L44.6715 2.84174Z"-->
<!--        fill="#EDFFA3"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M16.4286 51.0625L16.4202 51.0266L0 59.1305L25.4859 91.9455L25.5011 91.9385L25.5007 91.9366L16.4286 51.0625ZM24.1731 89.1326L15.9386 52.0321L1.06136 59.3745L24.1731 89.1326Z"-->
<!--        fill="#F8A954"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M0.0655672 59.0985L0 59.1308L0.040665 59.0791L14.171 40.9211L14.1795 40.9642L16.4216 51.0269L0.0655672 59.0985ZM2.38713 57.185L15.6319 50.6488L13.8159 42.4986L2.38713 57.185Z"-->
<!--        fill="#FAB962"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M9.11316 18.2118L25.5348 26.3172L25.525 26.3287L0.040665 59.0792L0 59.1309L9.11316 18.2118ZM9.59465 19.2173L1.33797 56.2907L13.6262 40.4983L24.4721 26.5605L9.59465 19.2173Z"-->
<!--        fill="#EE552C"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M9.11328 18.2112L25.5349 26.3166L45.9622 0.0639276L45.9628 0.0632333H45.9613L45.9619 0.0625623L46.0076 0.00574849L46.0118 0L46.0048 0.00347149L9.11328 18.2112ZM43.6257 1.94552L10.669 18.2112L25.3363 25.4506L43.6257 1.94552Z"-->
<!--        fill="#FFA566"-->
<!--      />-->
<!--      <path-->
<!--        fill-rule="evenodd"-->
<!--        clip-rule="evenodd"-->
<!--        d="M57.376 51.0275L55.1366 40.972L55.1338 40.9619L55.1254 40.9202L55.1437 40.9303L62.4339 44.526L62.4353 44.5274L62.4662 44.5432L57.376 51.0275ZM62.8495 43.9659L63.5235 44.3114L57.02 52.5962L54.4685 41.1394L54.4639 41.1227L54.1562 39.5989L55.4625 40.3198L62.8443 43.9606L62.8495 43.9659Z"-->
<!--        fill="#42854B"-->
<!--      />-->
<!--    </svg>-->
  <slot name="icon" />
    <h2 :class="isDark ? 'dark-header' : 'light-header'">{{ title }}</h2>
  </header>
</template>

<script>
import {store} from "@/store/store";

export default {
  store: store,
  props: {
    title: {
      type: String,
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark
    }
  }
};
</script>

<style scoped>
header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2vw;
  max-inline-size: clamp(30rem, 50rem, 100vw);
}
@media screen and (max-width: 830px) {
  header {
    flex-flow: column;
    align-items: flex-start;
    inline-size: 100%;
  }
}

h2 {
  font-weight: 600;
  text-align: left;
  font-size: x-large;
  line-height: 1.3;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
}
@media screen and (max-width: 830px) {
  h2 {
    inline-size: 100%;
    word-wrap: normal;
    letter-spacing: 0.2rem;
  }
}
</style>
