<template>
  <div class="figure-unit">
    <img
      :style="{ inlineSize: size + '%' }"
      @click="toggleShowcase"
      :src="src"
      :alt="alt"
    />
    <figcaption v-show="cap != undefined">{{ cap }}</figcaption>
    <div v-show="shouldZoomImage" class="showcase">
      <div :style="{ inlineSize: expSize + '%' }">
        <button aria-label="close" @click="toggleShowcase"></button>
        <img @click="toggleShowcase" :src="src" :alt="alt" />
        <figcaption v-show="cap != undefined">{{ cap }}</figcaption>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FigureImage",
  props: {
    src: String,
    alt: String,
    cap: String,
    size: {
      type: Number,
      default: 100,
    },
    expSize: {
      type: Number,
      default: 70,
    },
  },
  methods: {
    toggleShowcase(e) {
      this.shouldZoomImage = !this.shouldZoomImage;
    },
  },
  data() {
    return {
      shouldZoomImage: false,
    };
  },
};
</script>

<style lang="css" scoped>
.showcase {
  display: grid;
  place-content: center;
  place-items: center;
  background-color: hsla(222, 53%, 15%, 0.829);
  position: fixed;
  block-size: 100vh;
  margin: 0;
  inline-size: 100vw;
  z-index: 1000;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
:is(.showcase) button:hover {
  cursor: pointer;
}
:is(.showcase) button {
  display: grid;
  place-content: center center;
  padding: 0.3rem;
  border: none;
  border-radius: 2rem;
  background: var(--color-background-primary-dark)
    url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.117 3.90469L3.90473 12.117M3.88301 3.88303L12.0953 12.0953' stroke='white' stroke-width='1.1' stroke-linecap='round'/%3E%3C/svg%3E%0A")
    no-repeat center center;
  block-size: 1.5rem;
  inline-size: 1.5rem;
  place-self: end;
}
:is(.showcase) div {
  gap: 1rem;
  background-color: var(--color-orange-2);
  display: grid;
  place-content: center center;
  padding: 1rem;
}
:is(.showcase) img {
  inline-size: 100%;
}
:is(.showcase) img:hover {
  transform: none;
  cursor: initial;
}
.figure-unit {
  display: grid;
  /* grid-template-rows: 1rem 1fr; */
  place-items: center;
  block-size: fit-content;
}
.figure-unit:after {
  content: "Click to expand";
  font-size: small;
  color: transparent;
  transition: transform ease-in-out 100ms;
  transform: translateY(-1rem);
}
.figure-unit:hover:after {
  transform: translateY(0);
  color: var(--color-background-primary-dark);
}
.instr {
  background-color: hsla(0, 0%, 0%, 0.118);
  block-size: 100%;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border-radius: 0.3rem 0.3rem 0 0;
  transition: translateY ease-in-out 100ms;
}
.showInstr {
  transform: translateY(0);
}
img {
  /* inline-size: 100%; */
  transition: transform ease-out 100ms;
}
img::after {
  content: "Click to expand";
  display: block;
  block-size: 2rem;
  inline-size: 2.5rem;
  background: yellow;
}

img:hover {
  cursor: zoom-in;
  transform: scale(1.02);
}
</style>
