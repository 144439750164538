<template>
  <div class="row-container">
    <slot name="1"></slot>
    <slot name="2"></slot>
    <slot name="3"></slot>
    <slot name="4"></slot>
  </div>
</template>

<script>
export default {
  name: "Row",
  props: {
    imageList: [],
  },
};
</script>

<style scoped>
.row-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, minmax(13rem, 1fr));
  gap: 1rem;
  block-size: fit-content;
}
</style>
