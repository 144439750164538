<template>
  <div
    :class="{
      'equal-grid-container': isEqualSizes,
      'dynamic-grid-container': !isEqualSizes,
    }"
  >
    <slot name="1"></slot>
    <slot name="2"></slot>
    <slot name="3"></slot>
    <slot name="4"></slot>
  </div>
</template>

<script>
export default {
  name: "Grid",
  props: {
    imageList: [],
    isEqualSizes: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.equal-grid-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(auto-fill, minmax(13rem, 1fr));
  inline-size: 100%;
  gap: 1rem;
  block-size: fit-content;
}
@media screen and (max-width: 830px) {
  .equal-grid-container {
    grid-template-columns: 1fr;
  }
}
.dynamic-grid-container {
  inline-size: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(2rem, 20rem));
}
@media screen and (max-width: 830px) {
  .dynamic-grid-container {
    flex-flow: column;
    inline-size: 100%;
  }
}
</style>
