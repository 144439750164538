<template>
  <main class="outer-container">
    <BackToTop />
    <div class="inner-container">
      <div class="s-header">
        <SectionHeader :headerText="headerText" headerSize="xSmall" />
      </div>
      <img
        :class="[
          'header-image',
          shouldContainImage ? 'header-image-contain' : 'header-image-cover',
        ]"
        :src="headerImage"
        loading="lazy"
      />
      <div class="content-container">
        <div class="s-header-mid">
          <SectionHeader
            :headerText="sectionTypeName"
            :isLarge="false"
            headerSize="xSmall"
          />
        </div>
        <Header :title="title">
          <template v-slot:icon>
            <slot name="icon" />
          </template>
        </Header>
        <slot name="body" />
      </div>
    </div>
  </main>
</template>

<script>
import BackToTop from "@/components/controls/button/BackToTop.vue";
import SectionHeader from "../../../components/section_header/SectionHeader.vue";
import { written } from "@/core/data/written_data";
import Header from "@/components/info/desktop/Header.vue";
import LumiDSBody from "@/components/info/desktop/LumiDSBody.vue";
import { store } from "@/store/store";
import LumiIcon from "@/assets/icon/lumi_icon.vue";

export default {
  name: "Article",
  store: store,
  props: {
    headerText: String,
    headerImage: String,
    sectionTypeName: String,
    title: String,
    shouldContainImage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LumiIcon,
    BackToTop,
    Header,
    SectionHeader,
    LumiDSBody,
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
  },
};
</script>

<style scoped>
a:link,
a:focus,
a:visited {
  color: #fa713f;
}

.outer-container {
  width: 100vw;
  /* margin: 5rem 0 11% 0; */
  display: grid;
  block-size: 100%;
}

.inner-container {
  display: grid;
  block-size: fit-content;
  grid-template-rows: 20.9rem 1fr;
  grid-template-columns: minmax(11rem, 16vw) 1fr;
  padding-block-end: 10rem;
}

@media screen and (max-width: 830px) {
  .inner-container {
    grid-template-columns: 1fr;
  }
}

.header-image-contain {
  object-fit: contain;
}

.header-image-cover {
  object-fit: cover;
}

.header-image {
  z-index: 0;
  /* left: 19rem; */
  block-size: 100%;
}

@media screen and (max-width: 830px) {
  .header-image {
    inline-size: 100%;
  }
}

img {
  inline-size: 100%;
}

.header {
  inline-size: 100%;
  display: grid;
  grid-template-columns: minmax(11rem, 20vw) 1fr;
  grid-template-rows: 24vw;
}

.content-container {
  top: 3rem;
  padding-block-start: 2rem;
  padding-inline-end: 3rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  grid-column: 2 / span 2;
  inline-size: 100%;
  box-sizing: border-box;
  max-inline-size: 75ch;
}

@media screen and (max-width: 830px) {
  .content-container {
    top: 26rem;
    grid-column: 1 / span 3;
    top: 0;
    inline-size: 100%;
    padding-inline: 4rem;
    padding-block-start: 2rem;
    gap: 2rem;
  }
}

.container {
  width: 100vw;
  display: grid;
  grid-template-rows: auto;
  place-items: center;
}

.header-image {
  z-index: 0;
  top: 0;
  right: 0;
}

@media screen and (max-width: 830px) {
  .header-image {
    grid-column: 1 / span 3;
  }
}

.s-header-mid {
  display: none;
}

@media screen and (max-width: 830px) {
  .s-header-mid {
    display: grid;
    block-size: 100%;
    inline-size: fit-content;
    place-content: center center;
  }
}

.s-header {
  display: grid;
  block-size: 18rem;
  position: sticky;
  top: 3rem;
  place-content: center center;
}

@media screen and (max-width: 830px) {
  .s-header {
    display: none;
  }
}
</style>
