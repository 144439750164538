<template>
  <div class="lumi-ds-body">
    <Role :roles="['UI Designer', 'UX Designer']" />
    <Intro :body="introBody" :title="introTitle" />
    <div class="details">
      <Section :body="step1Body" :isSubSection="false" :title="step1Title" />
      <Section :body="colorBody" :isSubSection="true" :title="colorTitle" />
      <FigureImage :alt="'12 step luminance swatch'" :src="colors" />
      <Section :body="spacingBody" :isSubSection="true" :title="spacingTitle" />
      <FigureImage :alt="'spacing intervals'" :src="spacing" />
      <Section :body="iconsBody" :isSubSection="true" :title="iconsTitle" />
      <FigureImage :alt="'system icons'" :src="icons" />
      <Section
        :body="barsAndDotsBody"
        :isSubSection="true"
        :title="barsAndDotsTitle"
      />
      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :isOnDark="false"
            :multiVariations="barVariations"
            :should-force-light="true"
            :size="6"
            label="Bars"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :isOnDark="false"
            :multiVariations="splitBarVariations"
            :should-force-light="true"
            :size="6"
            label="Split Bars"
          />
        </template>
        <template v-slot:3>
          <StagedFigureImage
            :isOnDark="false"
            :multiVariations="dotVariations"
            :should-force-light="true"
            :size="0.7"
            label="Dots"
          />
        </template>
      </Grid>
      <Section
        :body="moleculesBody"
        :isSubSection="false"
        :title="moleculesTitle"
      />
      <Section :body="buttonsBody" :isSubSection="true" :title="buttonsTitle" />
      <div class="for-large">
        <StagedFigureImage
          :isOnDark="false"
          :multiVariations="btnMultiVariations"
          :should-force-light="true"
          :size="3"
          label="Buttons"
        />
      </div>
      <div class="for-small">
        <StagedFigureImage
          :expSize="90"
          :should-force-light="true"
          :size="100"
          :src="buttons"
          alt="buttons"
          label="Buttons"
        />
      </div>
      <Section
        :body="checkboxBody"
        :isSubSection="true"
        :title="checkboxTitle"
      />
      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :alt="'checkboxes and radio buttons'"
            :expSize="1"
            :isOnDark="false"
            :multiVariations="checkItemsVariations"
            :should-force-light="true"
            :size="1.5"
            :src="checkItemBtns"
            label="Check Items"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :alt="'toggles buttons'"
            :expSize="2"
            :isOnDark="false"
            :multiVariations="toggleVariations"
            :should-force-light="true"
            :size="2"
            ge
            label="Toggle"
          />
        </template>
      </Grid>

      <Section :body="bannerBody" :isSubSection="true" :title="bannerTitle" />
      <div class="for-large">
        <Row>
          <template v-slot:1>
            <StagedFigureImage
              :alt="'information banners'"
              :expSize="90"
              :isOnDark="false"
              :multi-variations="bannerInfoVariations"
              :should-force-light="true"
              :size="30"
              label="Info"
            />
          </template>
          <template v-slot:2>
            <StagedFigureImage
              :alt="'warning banners'"
              :expSize="90"
              :isOnDark="false"
              :multi-variations="bannerWarningVariations"
              :should-force-light="true"
              :size="30"
              label="Warning"
            />
          </template>
          <template v-slot:3>
            <StagedFigureImage
              :alt="'error banners'"
              :expSize="90"
              :isOnDark="false"
              :multi-variations="bannerErrorVariations"
              :should-force-light="true"
              :size="30"
              label="Error"
            />
          </template>
          <template v-slot:4>
            <StagedFigureImage
              :alt="'success banners'"
              :expSize="90"
              :isOnDark="false"
              :multi-variations="bannerSuccessVariations"
              :should-force-light="true"
              :size="30"
              label="Success"
            />
          </template>
        </Row>
      </div>
      <div class="for-small">
        <Row>
          <template v-slot:1>
            <StagedFigureImage
              :alt="'information banners'"
              :expSize="90"
              :isOnDark="false"
              :should-force-light="true"
              :size="100"
              :src="bannerInfo"
              label="Info"
            />
          </template>
          <template v-slot:2>
            <StagedFigureImage
              :alt="'warning banners'"
              :expSize="90"
              :isOnDark="false"
              :should-force-light="true"
              :size="100"
              :src="bannersWarning"
              label="Warning"
            />
          </template>
          <template v-slot:3>
            <StagedFigureImage
              :alt="'error banners'"
              :expSize="90"
              :isOnDark="false"
              :should-force-light="true"
              :size="100"
              :src="bannersError"
              label="Error"
            />
          </template>
          <template v-slot:4>
            <StagedFigureImage
              :alt="'success banners'"
              :expSize="90"
              :isOnDark="false"
              :should-force-light="true"
              :size="100"
              :src="bannersSuccess"
              label="Success"
            />
          </template>
        </Row>
      </div>
      <Section
        :body="tooltipsBody"
        :isSubSection="true"
        :title="tooltipsTitle"
      />
      <Grid :isEqualSizes="true">
        <template v-slot:1>
          <StagedFigureImage
            :alt="'text-only tooltip'"
            :expSize="40"
            :isOnDark="true"
            :size="90"
            :src="tooltipsTextOnly"
            label="Text-only Tooltip"
          />
          <StagedFigureImage
            :alt="'text list tooltip'"
            :expSize="40"
            :isOnDark="true"
            :size="90"
            :src="tooltipsTextList"
            label="Text List Tooltip"
          />
          <StagedFigureImage
            :alt="'graphic text list tooltip'"
            :expSize="40"
            :isOnDark="true"
            :size="90"
            :src="tooltipsGraphicTextList"
            label="Graphic Text List Tooltip"
          />
        </template>
      </Grid>
      <Section
        :body="organismsBody"
        :isSubSection="false"
        :title="organismsTitle"
      />
      <Section :body="vizBody" :isSubSection="true" :title="vizTitle" />
      <Grid :isEqualSizes="true">
        <template v-slot:1>
          <StagedFigureImage
            :alt="'Bar chart'"
            :expSize="40"
            :isOnDark="false"
            :should-force-light="true"
            :size="100"
            :src="chartBar"
            label="Bar Chart"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :alt="'split bar chart'"
            :expSize="40"
            :isOnDark="false"
            :should-force-light="true"
            :size="100"
            :src="chartSplitBar"
            label="Split Bar Chart"
          />
        </template>
        <template v-slot:3>
          <StagedFigureImage
            :alt="'plot chart'"
            :expSize="40"
            :isOnDark="false"
            :should-force-light="true"
            :size="100"
            :src="chartPlot"
            label="Plot Chart"
          />
        </template>
        <template v-slot:4>
          <StagedFigureImage
            :alt="'word cloud chart'"
            :expSize="60"
            :isOnDark="false"
            :should-force-light="true"
            :size="100"
            :src="chartWordCloud"
            label="Word Cloud Chart"
          />
        </template>
      </Grid>
      <Section :body="tilesBody" :isSubSection="true" :title="tilesTitle" />
      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :alt="'surface of the tile'"
            :expSize="20"
            :size="30"
            :src="tileSurface"
            label="Base surface"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :alt="'a tile with a prevalent graph'"
            :expSize="45"
            :size="90"
            :src="tilePrevalent"
            label="Prevalent tile"
          />
        </template>
      </Grid>
      <Section
        :body="sidePanelBody"
        :isSubSection="true"
        :title="sidePanelTitle"
      />
      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :alt="'side panel'"
            :expSize="50"
            :size="90"
            :src="sidePanelArr"
            label="Side panel"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :alt="'viewbox'"
            :expSize="50"
            :size="70"
            :src="viewbox"
            cap="The view box displays overview statistics of the user’s data. It is made up of a list and a dropdown. "
            label="Viewbox"
          />
          <StagedFigureImage
            :alt="'tabs'"
            :expSize="35"
            :size="90"
            :src="tabs"
            cap="Each tab contains a unique set of controls with unique responsibilities. The tabs make good use of almost every type of component in the design system."
            label="Tabs"
          />
        </template>
      </Grid>
      <Section :body="layoutBody" :title="layoutTitle" />
      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :is-on-dark="false"
            :should-force-light="true"
            :src="layoutSidePanelMain"
            alt="side panel layout"
            label="Layout / Side Panel + Main"
          />
          <StagedFigureImage
            :is-on-dark="false"
            :should-force-light="true"
            :src="layoutHeaderRows"
            alt="layout with header + rows"
            label="Layout / header + rows"
          />
          <StagedFigureImage
            :is-on-dark="false"
            :should-force-light="true"
            :src="layoutGrid"
            alt="layout with grid"
            label="Layout / Grid"
          />
          <StagedFigureImage
            :is-on-dark="false"
            :should-force-light="true"
            :src="layoutQuarterThreeQuarters"
            alt="layout with quarter, three-quarters sections"
            label="Layout / Quarter + Three-Quarters"
          />
        </template>
      </Grid>
      <Section :body="pagesBody" :title="pagesTitle" />
      <Row>
        <template v-slot:1>
          <StagedFigureImage
            :exp-size="50"
            :src="pagesHighlights"
            alt="higlights page"
            label="Highlights page"
          />
          <StagedFigureImage
            :src="pagesVolume"
            alt="volume page"
            label="Volume page"
          />
        </template>
      </Row>
    </div>
  </div>
</template>

<script>
import Intro from "@/components/info/desktop/Intro.vue";
import Section from "@/components/info/desktop/Section.vue";
import FigureImage from "@/components/info/desktop/figure/FigureImage.vue";
import StagedFigureImage from "@/components/info/desktop/figure/staged_figure.vue";
import Grid from "@/components/info/desktop/figure/grid.vue";
import Row from "@/components/info/desktop/figure/row.vue";
import { written } from "@/core/data/written_data";
import tilePrevalent from "@/assets/images/design_system/analytics_ui/tile_prevalent.webp";
import tileSurface from "@/assets/images/design_system/analytics_ui/tile_base.webp";
import viewbox from "@/assets/images/design_system/analytics_ui/viewbox.webp";
import colors from "@/assets/images/design_system/analytics_ui/colors.webp";
import tabs from "@/assets/images/design_system/analytics_ui/tabs.webp";
import sidePanelArr from "@/assets/images/design_system/analytics_ui/sidepanel_arr.webp";
import sidePanelBreakdown from "@/assets/images/design_system/analytics_ui/sidepanel_breakdown.webp";
import chartBar from "@/assets/images/design_system/analytics_ui/chart_bar.webp";
import chartPlot from "@/assets/images/design_system/analytics_ui/chart_plot.webp";
import chartSplitBar from "@/assets/images/design_system/analytics_ui/chart_split_bar.webp";
import chartWordCloud from "@/assets/images/design_system/analytics_ui/chart_word_cloud.webp";
import tooltipsTextOnly from "@/assets/images/design_system/analytics_ui/tooltips_text_only.webp";
import tooltipsGraphicTextList from "@/assets/images/design_system/analytics_ui/tooltips_graphic_text_list.webp";
import tooltipsTextList from "@/assets/images/design_system/analytics_ui/tooltips_graphic_text_list.webp";
import bannersSuccess from "@/assets/images/design_system/analytics_ui/banners_success.webp";
import bannersError from "@/assets/images/design_system/analytics_ui/banners_error.webp";
import checkItemBtns from "@/assets/images/design_system/analytics_ui/check_items.webp";
import toggleBtns from "@/assets/images/design_system/analytics_ui/toggles.webp";
import buttons from "@/assets/images/design_system/analytics_ui/buttons.webp";
import icons from "@/assets/images/design_system/analytics_ui/icons.webp";
import spacing from "@/assets/images/design_system/analytics_ui/spacing.webp";
import bars from "@/assets/images/design_system/analytics_ui/bars.webp";
import splitBars from "@/assets/images/design_system/analytics_ui/split_bars.webp";
import dots from "@/assets/images/design_system/analytics_ui/dots.webp";
import bannerInfo from "@/assets/images/design_system/analytics_ui/banners_info.webp";
import bannerInfoWithUndo from "@/assets/images/design_system/analytics_ui/banners_info_with_undo.webp";
import bannerSuccess from "@/assets/images/design_system/analytics_ui/banners_success.webp";
import bannerSuccessWithUndo from "@/assets/images/design_system/analytics_ui/banners_success_with_undo.webp";
import bannerErrorPlain from "@/assets/images/design_system/analytics_ui/banner_error_plain.webp";
import bannerErrorWithUndo from "@/assets/images/design_system/analytics_ui/banner_error_with_undo.webp";
import bannersWarning from "@/assets/images/design_system/analytics_ui/banners_warning.webp";
import bannersWarningWithUndo from "@/assets/images/design_system/analytics_ui/banners_warning_with_undo.webp";
import iRadioChecked from "@/assets/images/design_system/analytics_ui/radio_checked.webp";
import iRadioUnChecked from "@/assets/images/design_system/analytics_ui/radio_unchecked.webp";
import iRadioDisabled from "@/assets/images/design_system/analytics_ui/radio_disabled.webp";
import iCheckboxChecked from "@/assets/images/design_system/analytics_ui/checkbox_checked.webp";
import iCheckboxUnchecked from "@/assets/images/design_system/analytics_ui/checkbox_unchecked.webp";
import iCheckboxDisabled from "@/assets/images/design_system/analytics_ui/checkbox_disabled.webp";
import toggleDisabled from "@/assets/images/design_system/analytics_ui/toggle_disabled.webp";
import toggleOn from "@/assets/images/design_system/analytics_ui/toggle_on.webp";
import toggleOff from "@/assets/images/design_system/analytics_ui/toggle_off.webp";
import btnBlueResting from "@/assets/images/design_system/analytics_ui/btn_blue_resting.webp";
import btnBlueHover from "@/assets/images/design_system/analytics_ui/btn_blue_hover.webp";
import btnGreenResting from "@/assets/images/design_system/analytics_ui/btn_green_resting.webp";
import btnGreenHover from "@/assets/images/design_system/analytics_ui/btn_green_hover.webp";
import btnYellowResting from "@/assets/images/design_system/analytics_ui/btn_yellow_resting.webp";
import btnYellowHover from "@/assets/images/design_system/analytics_ui/btn_yellow_hover.webp";
import btnRedResting from "@/assets/images/design_system/analytics_ui/btn_red_resting.webp";
import btnRedHover from "@/assets/images/design_system/analytics_ui/btn_red_hover.webp";
import btnGrayResting from "@/assets/images/design_system/analytics_ui/btn_gray_resting.webp";
import btnGrayHover from "@/assets/images/design_system/analytics_ui/btn_gray_hover.webp";
import btnDisabled from "@/assets/images/design_system/analytics_ui/btn_disabled.webp";
import dotBlue from "@/assets/images/design_system/analytics_ui/dot_blue.webp";
import dotLightBlue from "@/assets/images/design_system/analytics_ui/dot_light_blue.webp";
import dotRed from "@/assets/images/design_system/analytics_ui/dot_red.webp";
import dotLightRed from "@/assets/images/design_system/analytics_ui/dot_light_red.webp";
import splitBarsPos from "@/assets/images/design_system/analytics_ui/split_bars_pos.webp";
import splitBarsNeg from "@/assets/images/design_system/analytics_ui/split_bars_neg.webp";
import splitBarsPosNeg from "@/assets/images/design_system/analytics_ui/split_bars_pos_neg.webp";
import barBlue from "@/assets/images/design_system/analytics_ui/bar_blue.webp";
import barGreen from "@/assets/images/design_system/analytics_ui/bar_green.webp";
import barYellow from "@/assets/images/design_system/analytics_ui/bar_yellow.webp";
import barOrange from "@/assets/images/design_system/analytics_ui/bar_orange.webp";
import barPink from "@/assets/images/design_system/analytics_ui/bar_pink.webp";
import barPurple from "@/assets/images/design_system/analytics_ui/bar_purple.webp";
import layoutSidePanelMain from "@/assets/images/design_system/analytics_ui/layout_side_panel_main.webp";
import layoutHeaderRows from "@/assets/images/design_system/analytics_ui/layout_header_rows.webp";
import layoutGrid from "@/assets/images/design_system/analytics_ui/layout_grid.webp";
import layoutQuarterThreeQuarters from "@/assets/images/design_system/analytics_ui/layout_quarter_three_quarters.webp";
import pagesHighlights from "@/assets/images/design_system/analytics_ui/pages_highlights.webp";
import pagesVolume from "@/assets/images/design_system/analytics_ui/pages_volume.webp";
import bannerSuccessPlain from "@/assets/images/design_system/analytics_ui/banner_success_plain.webp";
import bannerWarningPlain from "@/assets/images/design_system/analytics_ui/banner_warning_plain.webp";
import bannerInforPlain from "@/assets/images/design_system/analytics_ui/banner_info_plain.webp";
import Role from "@/components/info/desktop/Role.vue";

export default {
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleShowcase(e) {
      this.shouldZoomImage = !this.shouldZoomImage;
    },
    showInstr(e) {
      this.instrPos = 0;
    },
  },
  name: "LumiDSBody",
  components: {
    Role,
    Intro,
    Section,
    FigureImage,
    StagedFigureImage,
    Grid,
    Row,
  },
  data() {
    return {
      overlayRect: {
        x: 0,
        y: 0,
      },
      instrPos: 8,
      shouldZoomImage: false,
      instr: "instr",
    };
  },
  computed: {
    colors: () => colors,
    spacing: () => spacing,
    bars: () => bars,
    splitBars: () => splitBars,
    dots: () => dots,
    icons: () => icons,
    buttons: () => buttons,
    checkItemBtns: () => checkItemBtns,
    toggleBtns: () => toggleBtns,
    bannersInfo: () => bannersInfo,
    bannerSuccess: () => bannerSuccess,
    bannerInfo: () => bannerInfo,
    bannersWarning: () => bannersWarning,
    bannersError: () => bannersError,
    bannersSuccess: () => bannersSuccess,
    tooltipsTextOnly: () => tooltipsTextOnly,
    tooltipsGraphicTextList: () => tooltipsGraphicTextList,
    tooltipsTextList: () => tooltipsTextList,
    chartBar: () => chartBar,
    chartSplitBar: () => chartSplitBar,
    chartWordCloud: () => chartWordCloud,
    chartPlot: () => chartPlot,
    tilePrevalent: () => tilePrevalent,
    tileSurface: () => tileSurface,
    sidePanelArr: () => sidePanelArr,
    sidePanelBreakdown: () => sidePanelBreakdown,
    layoutGrid: () => layoutGrid,
    layoutSidePanelMain: () => layoutSidePanelMain,
    layoutHeaderRows: () => layoutHeaderRows,
    layoutQuarterThreeQuarters: () => layoutQuarterThreeQuarters,
    pagesHighlights: () => pagesHighlights,
    pagesVolume: () => pagesVolume,
    viewbox: () => viewbox,
    tabs: () => tabs,
    bannerErrorVariations: () => [
      ["", ""],
      ["Plain", bannerErrorPlain],
      ["With Undo", bannerErrorWithUndo],
    ],
    bannerSuccessVariations: () => [
      ["", ""],
      ["Plain", bannerSuccessPlain],
      ["With Undo", bannerSuccessWithUndo],
    ],
    bannerWarningVariations: () => [
      ["", ""],
      ["Plain", bannerWarningPlain],
      ["With Undo", bannersWarningWithUndo],
    ],
    bannerInfoVariations: () => [
      ["", ""],
      ["Plain", bannerInforPlain],
      ["With Undo", bannerInfoWithUndo],
    ],
    barVariations: () => [
      ["", ""][("Blue", barBlue)],
      ["Green", barGreen],
      ["Yellow", barYellow],
      ["Orange", barOrange],
      ["Pink", barPink],
      ["Purple", barPurple],
    ],
    splitBarVariations: () => [
      ["", ""],
      ["Positive", splitBarsPos],
      ["Negative", splitBarsNeg],
      ["Positive Negative", splitBarsPosNeg],
    ],
    toggleVariations: () => [
      ["On", toggleOn],
      ["Off", toggleOff],
      ["Disabled", toggleDisabled],
    ],
    checkItemsVariations: () => [
      ["", "Radio", "Checkbox"],
      ["Checked", iRadioChecked, iCheckboxChecked],
      ["UnChecked", iRadioUnChecked, iCheckboxUnchecked],
      ["Disabled", iRadioDisabled, iCheckboxDisabled],
    ],
    dotVariations: () => [
      ["", ""],
      ["Blue", dotBlue],
      ["Light Blue", dotLightBlue],
      ["Red", dotRed],
      ["Light Red", dotLightRed],
    ],
    btnMultiVariations: () => [
      ["", "Blue", "Green", "Red", "Yellow", "Gray"],
      [
        "Resting",
        btnBlueResting,
        btnGreenResting,
        btnRedResting,
        btnYellowResting,
        btnGrayResting,
      ],
      [
        "Hover",
        btnBlueHover,
        btnGreenHover,
        btnRedHover,
        btnYellowHover,
        btnGrayHover,
      ],
      [
        "Disabled",
        btnDisabled,
        btnDisabled,
        btnDisabled,
        btnDisabled,
        btnDisabled,
      ],
    ],

    introTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.description.title,
    introBody: () =>
      written.uxProjects.luminosoDesignSystem.details.description.description,
    step1Title: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].title,
    step1Body: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].intro,
    colorTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[0].title,
    colorBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[0].description,
    colorsTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[0].title,
    colorsBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[0].description,
    spacingTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[1].title,
    spacingBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[1].description,
    iconsTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[2].title,
    iconsBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[2].description,
    barsAndDotsTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[3].title,
    barsAndDotsBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].subsections[0]
        .details[3].description,
    moleculesTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].title,
    moleculesBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].intro,
    buttonsTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[0]
        .title,
    buttonsBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[0]
        .intro,
    checkboxTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[1]
        .title,
    checkboxBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[1]
        .intro,
    bannerTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[2]
        .title,
    bannerBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[2]
        .intro,
    tooltipsTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[3]
        .title,
    tooltipsBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[1].subsections[3]
        .intro,
    organismsTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].title,
    organismsBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].intro,
    vizTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[0]
        .title,
    vizBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[0]
        .intro,
    tilesTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[1]
        .title,
    tilesBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[1]
        .intro,
    layoutTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[3]
        .title,
    layoutBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[3]
        .intro,
    pagesTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[4]
        .title,
    pagesBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[4]
        .intro,
    sidePanelTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[5]
        .title,
    sidePanelBody: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[2].subsections[5]
        .intro,
  },
};
</script>

<style scoped>
.lumi-ds-body {
  display: grid;
  gap: 4rem;
  inline-size: 100%;
  box-sizing: border-box;
}

.details {
  display: grid;
  gap: 5.25rem;
}

.for-small {
  display: none;
}

.for-large {
  display: block;
}

@media screen and (max-width: 830px) {
  .for-small {
    display: block;
    inline-size: fit-content;
  }

  .for-large {
    display: none;
  }
}
</style>
