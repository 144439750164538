<template>
  <div
    :class="[
      multiVariations == undefined && variations == undefined
        ? 'zoomable-figure-unit'
        : 'figure-unit',
      surfaceClass,
    ]"
    :style="{
      inlineSize: multiVariations == undefined ? size + '%' : 'fit-content',
    }"
  >
    <figcaption
      v-show="label != undefined"
      :class="['fig-label', 'label', labelClass]"
    >
      {{ label }}
    </figcaption>
    <table v-show="multiVariations != undefined" class="multi-variations">
      <tr v-for="(row, indexx) in multiVariations" :key="indexx">
        <td
          v-for="(item, index) in row"
          :key="index"
          :class="borderClass(index, indexx)"
        >
          <img
            v-if="item.includes('/')"
            :src="item"
            :style="{ inlineSize: `${size}rem` }"
            alt=""
            @click="(event) => toggleShowcase(event, `${row[index]}`)"
          />
          <p v-if="item.includes('/') == false" :class="['label', labelClass]">
            {{ item }}
          </p>
        </td>
      </tr>
    </table>
    <div v-show="variations != undefined" class="variations">
      <div v-for="item in variations" :key="item.label">
        <figcaption :class="['label', labelClass]">{{ item.label }}</figcaption>
        <img
          :alt="alt"
          :src="item.img"
          class="var-img dashed-border"
          @click="(event) => toggleShowcase(event, item.label)"
        />
      </div>
    </div>
    <img
      v-show="variations == undefined && multiVariations == undefined"
      :alt="alt"
      :src="src"
      class="single-img zoomable"
      @click="toggleShowcase"
    />
    <figcaption
      v-show="cap != undefined"
      :class="['cap', isDark ? 'dark-label' : 'light-label']"
    >
      {{ cap }}
    </figcaption>
    <div v-show="shouldZoomImage" class="showcase">
      <div :style="{ inlineSize: expSize + '%' }">
        <button aria-label="close" @click="toggleShowcase"></button>
        <div class="exp-img-unit">
          <figcaption v-show="expLabel !== ''" :class="labelClass">
            {{ expLabel }}
          </figcaption>
          <img :alt="alt" :src="focusImage" @click="toggleShowcase" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store";

export default {
  name: "StagedFigureImage",
  store: store,
  props: {
    src: String,
    alt: String,
    cap: String,
    label: String,
    isOnDark: { type: Boolean, default: true },
    shouldForceLight: { type: Boolean, default: false },
    variations: Array,
    multiVariations: Array,
    size: {
      type: Number,
      default: 100,
    },
    expSize: {
      type: Number,
      default: 70,
    },
  },
  methods: {
    toggleShowcase(e, label) {
      this.shouldZoomImage = !this.shouldZoomImage;
      this.focusImage = e.target.src;
      this.expLabel = label;
      console.log(this.focusImage);
    },
    borderClass: function (index, indexx) {
      let result = [];
      if (index > 0 && indexx > 0) {
        if (this.shouldForceLight && this.isDark) {
          result.push("dashed-border");
          result.push("table-border-darker");
        } else {
          result.push("dashed-border");
          result.push("table-border-light");
        }
      }
      if (index > 0 && indexx == 0) {
        if (this.shouldForceLight && this.isDark) {
          result.push("dashed-border-bottom");
          result.push("table-border-darker");
        } else {
          result.push("dashed-border-bottom");
          result.push("table-border-light");
        }
      }
      return result;
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    surfaceClass: function () {
      if (this.shouldForceLight && this.isDark) {
        return "on-light-in-dark";
      } else if (this.isDark) {
        return "on-dark-in-dark";
      } else if (this.isOnDark) {
        return "on-dark";
      } else {
        return "on-light";
      }
    },
    labelClass: function () {
      if (this.shouldForceLight && this.isDark) {
        return "light-label-on-dark";
      } else if (this.isDark) {
        return "dark-label";
      } else {
        return "light-label";
      }
    },
  },
  data() {
    return {
      shouldZoomImage: false,
      focusImage: "",
      expLabel: "",
    };
  },
};
</script>

<style lang="css" scoped>
.showcase {
  display: grid;
  place-content: center;
  place-items: center;
  background-color: hsla(222, 53%, 15%, 0.829);
  position: fixed;
  block-size: 100vh;
  margin: 0;
  inline-size: 100vw;
  z-index: 1000;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

:is(.showcase) button:hover {
  cursor: pointer;
}

:is(.showcase) button {
  display: grid;
  place-content: center center;
  padding: 0.3rem;
  border: none;
  border-radius: 2rem;
  background: var(--color-background-primary-dark)
    url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.117 3.90469L3.90473 12.117M3.88301 3.88303L12.0953 12.0953' stroke='white' stroke-width='1.1' stroke-linecap='round'/%3E%3C/svg%3E%0A")
    no-repeat center center;
  block-size: 1.5rem;
  inline-size: 1.5rem;
  place-self: end;
}

.cap {
  text-align: left;
  font-size: small;
  color: var(--color-gray-100);
  line-height: 1.3;
  margin-block-start: 1rem;
}

:is(.showcase) div {
  gap: 1rem;
  background-color: var(--color-gray-25);
  display: grid;
  place-content: center center;
  padding: 1rem;
}

:is(.showcase) img {
  inline-size: 100%;
  box-shadow: var(--shadow-on-gray);
  border-radius: 1rem;
}

:is(.showcase) img:hover {
  transform: none;
  cursor: initial;
}

:is(.zoomable-figure-unit) img {
  inline-size: 100%;
}

.variations {
  display: grid;
  border: 0;
  border-radius: none;
  inline-size: 100%;
}

:is(.multi-variations) img {
  object-fit: cover;
}

.multi-variations {
  display: block;
}

:is(.variations) div {
  display: grid;
  grid-template-columns: 8ch 1fr;
  place-items: center;
  gap: 1rem;
  border-radius: 0;
}

.var-img {
  padding: 1rem;
  border-radius: 0 !important;
  inline-size: 100%;
}

:is(.variations) figcaption {
  text-align: left;
  inline-size: 100%;
  font-weight: normal;
  font-size: x-small;
}

.on-dark-in-dark {
  background-color: var(--color-background-primary-dark-on-background);
  box-shadow: var(--shadow-for-gray);
}

.on-dark {
  background-color: var(--color-gray-25);
  box-shadow: var(--shadow-for-gray);
}

.on-light {
  background-color: white;
  box-shadow: var(--shadow-for-light);
}

.on-light-in-dark {
  background-color: var(--color-gray-25);
  box-shadow: var(--shadow-for-light);
}

.zoomable-figure-unit,
.figure-unit {
  display: grid;
  place-items: flex-start;
  block-size: fit-content;
  padding: 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
}

@media screen and (max-width: 830px) {
  /*.zoomable-figure-unit {*/
  /*  inline-size: 100% !important;*/
  /*}*/
  :is(.zoomable-figure-unit) img {
    inline-size: 100% !important;
  }
}

.label {
  font-family: "IBM Plex Mono";
  font-size: small;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.0001rem;
}

.light-label {
  color: var(--color-gray-100);
}

.light-label-on-dark {
  color: var(--color-gray-200);
}

.dark-label {
  color: var(--color-gray-25);
}

.fig-label {
  margin-block-end: 2rem;
}

.zoomable-figure-unit:after {
  content: "Click to expand";
  font-size: small;
  color: transparent;
  transition: transform ease-in-out 100ms;
  transform: translateY(-1rem);
}

.zoomable-figure-unit:hover:after {
  transform: translateY(0);
  color: var(--color-background-primary-dark);
}

.instr {
  background-color: hsla(0, 0%, 0%, 0.118);
  block-size: 100%;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border-radius: 0.3rem 0.3rem 0 0;
  transition: translateY ease-in-out 100ms;
}

.showInstr {
  transform: translateY(0);
}

:is(.zoomable-figure-unit) img:first-child,
:is(.figure-unit) img:first-child {
  box-shadow: var(--shadow-on-gray);
}

.exp-img-unit {
  display: grid;
  place-items: start;
}

td {
  display: table-cell;
  box-sizing: border-box;
  vertical-align: middle;
}

td:first-child {
  padding-inline: 1rem;
}

td:first-child > p {
  font-size: small;
  font-weight: normal;
}

td:nth-child(n + 2) {
  padding: 1rem;
}

:is(td) img {
  inline-size: 100%;
  block-size: 100%;
}

img {
  transition: transform ease-out 100ms;
}

img::after {
  content: "Click to expand";
  display: block;
  block-size: 2rem;
  /* inline-size: 2.5rem; */
  background: yellow;
}

.zoomable:hover {
  cursor: zoom-in;
  transform: scale(1.02);
}
</style>
