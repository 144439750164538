<template>
  <section :class="isDark ? 'dark' : 'light'">
    <h2 :class="['header-2', isDark ? 'dark-header' : 'light-header']">
      {{ title }}
    </h2>
    <p :class="isDark ? 'body-dark' : 'body-light'" v-html="body"></p>
  </section>
</template>

<script>
import { store } from "@/store/store";

export default {
  name: "Intro",
  store: store,
  props: {
    title: {
      type: String,
    },
    body: {
      type: String,
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1.6;
}

.dark {
  color: var(--color-gray-25);
}

.light {
  color: var(--color-basic-black);
}

section {
  display: grid;
  text-align: left;
  gap: 3rem;
}
</style>
